/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 */
import './util/static/sitevision-slim.css';
import './util/static/toasts.css';

import './util/findPortlets';

import './Text/index-slim';
import './Image/index-slim';
import './JcrMenu/index-slim';
import './Search/Abc/index-slim';
import './Search/FacetedSearch/index-slim';
import './Search/SearchForm/index-slim';
import './Search/SearchForm2/index-slim';
import './Search/StandardSearch/index-slim';
import './Views/index-slim';
import './Archive/index-slim';
import './Blog/index-slim';
import './ContentList/index-slim';

import './Comments2/index-slim';
import './Contact2/index-slim';
import './Form/index-slim';
import './ImageMap/index-slim';
import './ImageSlideShow/index-slim';
import './ListBookmark2/index-slim';
import './Login/index-slim';
import './Media/index-slim';
import './Proxy/index-slim';
import './Rating/index-slim';
import './Related/index-slim';
import './TagCloudResult/index-slim';
import './TagCloud2/index-slim';
import './TellFriend/index-slim';
import './TwitterSearch/index-slim';
import './Toolbar/index-slim';
import './Youtube/index-slim';
import './vendor/friendlyCaptcha';
import './SharePoint/index-slim';
import './SharePoint/File/index-slim';
import './File/index-slim';
import './Pdf/index-slim';
import './Video/index-slim';
import './Booking/index-slim';
import './ImageExplorer/index-slim';
import './UserProfile/index-slim';
import './UserRegistration/index-slim';

import './i18n-common';

import sv from '@sv/core';
import { containsSocialPortlet } from './social/portletList';

if (sv.PageContext.socialEnabled && containsSocialPortlet()) {
  import(/* webpackChunkName: "social" */ './social/index-slim');
}

import './util/externalLinks';
