/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 */
import { hasPortlet } from '../util/findPortlets';

export const containsSocialPortlet = () => {
  for (let i = 0; i < socialPortletNames.length; i++) {
    if (hasPortlet(socialPortletNames[i])) {
      return true;
    }
  }

  return false;
};
//all portlets found in portlet.xml within namespace senselogic.sitevision.portlet.social.*
const socialPortletNames = [
  'sv-bookmarklist-portlet',
  'sv-calendar-portlet',
  'sv-contactsearch-portlet',
  'sv-contactsearchall-portlet',
  'sv-contactstatus-portlet',
  'sv-createdocument-portlet',
  'sv-fileshare-portlet',
  'sv-fileshareall-portlet',
  'sv-groupadmin-portlet',
  'sv-groupadminall-portlet',
  'sv-groupmemberstatus-portlet',
  'sv-grouptypeselector-portlet',
  'sv-likepage-portlet',
  'sv-messages-portlet',
  'sv-notifications-portlet',
  'sv-personalsettings-portlet',
  'sv-predefinedcontactsearch-portlet',
  'sv-profileimage-portlet',
  'sv-profileprogress-portlet',
  'sv-sendmessage-portlet',
  'sv-sharedlinks-portlet',
  'sv-sharepage-portlet',
  'sv-taglist-portlet',
  'sv-tagtimeline-portlet',
  'sv-tasks-portlet',
  'sv-timeline-portlet',
  'sv-timelineentry-portlet',
  'sv-timelineimages-portlet',
  'sv-timelinesearch-portlet',
  'sv-usercontacts-portlet',
  'sv-usercontactsall-portlet',
  'sv-userfields-portlet',
  'sv-userfollowers-portlet',
  'sv-usergroups-portlet',
  'sv-usergroupsall-portlet',
  'sv-comments2-portlet',
];
