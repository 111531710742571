/**
 * Copyright (C) Sitevision 2002-2023, all rights reserved
 */
import sv from '@sv/core';
import { hasPortlet } from '../../util/findPortlets';

if (hasPortlet('sv-searchform-portlet')) {
  import(/* webpackChunkName: "searchform-plugins" */ './plugins-slim').then(
    () => {
      const $ = window.$svjq;

      const getContainerId = (potentialId) => {
        const subStr = potentialId.substring(5);
        return `search${subStr}selectedState`;
      };

      $('.sv-searchform-portlet').each(function () {
        const $this = $(this);
        const htmlId = $this.attr('id');
        const $searchBox = $this.find('[name=query]');
        const portletId = sv.ObjectUtil.getObjectId(htmlId);
        const url = `/${sv.PageContext.pageId}/${portletId}.json?state=autoComplete`;

        $searchBox.svAutoComplete({
          source: url,
          minLength: 2,
          delay: 200,
          selectedValueContainerId: getContainerId(htmlId),
        });

        const $form = $this.find('form');
        $form.submit(() => {
          if ($searchBox.val() === '') {
            return false;
          }
        });
      });
    }
  );
}
