/**
 * Copyright (C) Sitevision AB 2002-2023, all rights reserved
 */
import sv from '@sv/core';
import { hasPortlet } from '../util/findPortlets';

if (hasPortlet('sv-imageslideshow-portlet')) {
  import(
    /* webpackChunkName: "imageslideshow-portlet" */ './imageSlideShow'
  ).then(() => {
    document.querySelectorAll('.sv-imageslideshow-portlet').forEach((item) => {
      const id = item.getAttribute('id');
      sv.createAndLoadSlideshowForId('#' + id);
    });
  });
}
