/**
 * Copyright (C) Sitevision 2002-2023, all rights reserved
 */
import { hasPortlet, hasSubPortlet } from '../util/findPortlets';

if (hasPortlet('sv-archive-portlet')) {
  import(/* webpackChunkName: "archive-plugins" */ './index');

  if (hasSubPortlet('sv-archive-portlet', '.sv-like')) {
    import(/* webpackChunkName: "likableUtil" */ './plugins-slim');
  }
}
