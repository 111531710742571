/**
 * Copyright (C) SiteVision AB 2002-2023, all rights reserved
 */
import { hasPortlet } from '../../util/findPortlets';

if (hasPortlet('sv-abc-portlet')) {
  import(
    /* webpackChunkName: "defaultlist" */ '../../util/static/defaultlist.css'
  );
}
