/**
 * Copyright (C) Sitevision 2002-2024, all rights reserved
 */
import { hasPortlet } from '../util/findPortlets';

if (hasPortlet('sv-userregistration-portlet')) {
  import(
    /* webpackChunkName: "userregistration-portlet" */ '../util/static/selfserviceFieldset.css'
  );
}
