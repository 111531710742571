/**
 * Copyright (C) Sitevision AB 2002-2023, all rights reserved
 *
 *  Selected views.
 */
if (
  document.querySelector(
    '.sv-sortable-view-container,.sv-portlet-restore,.sv-layout-restore,.sv-layout-minimize,.sv-view-selector'
  )
) {
  import(/* webpackChunkName: "viewUtil" */ './plugins-slim');
}
