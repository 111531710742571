/**
 * Copyright (C) SiteVision AB 2002-2024, all rights reserved
 */
import sv from '@sv/core';

const portlets = document.querySelectorAll(
  `div[id^="svid12"][class*="sv-portlet"],
  div[id^="svid12"][class*="sv-text-portlet"],
  .sv-error`
);

const knownSubSelectors = {
  'sv-text-portlet': [
    '.sv-tablesorter',
    '.sv-blockquote',
    '.sv-responsiveTable',
  ],
  'sv-archive-portlet': ['.sv-like'],
  'sv-blog-portlet': ['.sv-like'],
  'sv-contentlist-portlet': ['.sv-carousel', '.env-tabs'],
  'sv-tagcloud2-portlet': ['.sv-defaultlist'],
};

const portletSet = {};
portlets.forEach((elem) => {
  const portletSelector = elem.classList[0];
  portletSet[portletSelector] = {};

  const subPortletSelectors = knownSubSelectors[portletSelector];
  if (subPortletSelectors) {
    const data = portletSet[portletSelector].data || new Set();
    subPortletSelectors.forEach((subSelector) => {
      if (document.querySelector(subSelector)) {
        data.add(subSelector);
      }
    });
    portletSet[portletSelector].data = data;
  }
});
sv.foundPortlets = portletSet;

export const hasPortlet = (portletSelector) => {
  return Object.hasOwn(sv.foundPortlets, portletSelector);
};

export const hasSubPortlet = (portletSelector, subPortletSelector) => {
  const portlet = sv.foundPortlets[portletSelector];
  if (!portlet) {
    return;
  }
  return portlet.data.has(subPortletSelector);
};
