/**
 * Copyright (C) Sitevision 2002-2023, all rights reserved
 */
import { hasPortlet, hasSubPortlet } from '../util/findPortlets';
import sv from '@sv/core';

const portletName = 'sv-text-portlet';

if (hasPortlet(portletName)) {
  import(
    /* webpackChunkName: "text-portlet" */
    /* webpackPreload: true */
    './css/text-slim.css'
  );

  if (hasSubPortlet(portletName, '.sv-blockquote')) {
    import(
      /* webpackChunkName: "blockquote-style" */ './css/text-blockquote-slim.css'
    );
  }

  if (hasSubPortlet(portletName, '.sv-tablesorter')) {
    import(/* webpackChunkName: "tablesorter-plugins" */ './plugins').then(
      () => {
        const $sortableContainers = window.jQuery(
          '.sv-text-portlet .sv-tablesorter'
        );
        sv.sortableTables = sv.sortableTables || {};
        sv.sortableTables.dateFormat =
          sv.sortableTables.dateFormat || 'ddmmyyyy';

        $sortableContainers.tablesorter({
          dateFormat: sv.sortableTables.dateFormat,
        });
      }
    );
  }
  if (hasSubPortlet(portletName, '.sv-responsiveTable')) {
    import(/* webpackChunkName: "responsive-tables" */ '../ResponsiveTables');
  }
}
