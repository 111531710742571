/**
 * Copyright (C) Sitevision 2002-2024, all rights reserved
 */
import { hasPortlet, hasSubPortlet } from '../util/findPortlets';

if (hasPortlet('sv-tagcloud2-portlet')) {
  import(/* webpackChunkName: "tagcloud2-portlet" */ './tagCloud.css');

  if (hasSubPortlet('sv-tagcloud2-portlet', '.sv-defaultlist')) {
    import(
      /* webpackChunkName: "tagcloud2-list" */ '../util/static/defaultlist.css'
    );
  }
}
