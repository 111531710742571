/**
 * Copyright (C) Sitevision AB 2002-2023, all rights reserved
 *
 *  Portlet toolbar, selected views.
 */
if (document.querySelector('.sv-portlet-toolbar,.sv-toolbar')) {
  import(/* webpackChunkName: "halflings" */ '../vendor/glyphicons/halflings');

  if (document.querySelector('.sv-toolbar')) {
    //settings for selected view on a layout
    import(/* webpackChunkName: "svdialog" */ '../vendor/svDialog');
  }
}
