/**
 * Copyright (C) SiteVision AB 2002-2024, all rights reserved
 */
import { hasPortlet } from '../util/findPortlets';

if (
  hasPortlet('sv-signup-portlet') ||
  hasPortlet('sv-dbform-portlet') ||
  hasPortlet('sv-survey-portlet') ||
  hasPortlet('sv-form-portlet')
) {
  import(/* webpackChunkName: "form-plugins" */ './commonFormPlugins');
}
