sv.i18n.common=sv.i18n.common || {};
sv.i18n.common.de={
   "networkErrorText": "Die Information konnte nicht aktualisiert werden. Es konnte kein Kontakt zum Server hergestellt werden.",
   "networkErrorTitle": "Fehler bei der Serverkommunikation",
   "decimal": ",",
   "cancel": "Abbrechen",
   "mins": "Min",
   "error-fileExists": "{0} ist bereits vorhanden.",
   "ok": "OK",
   "error-fileRenameHeading": "Der Name der Datei konnte nicht geändert werden",
   "add": "Hinzufügen",
   "close": "Beenden",
   "new": "Neu",
   "error": "Fehler",
   "error-fileExistsHeading": "Die Datei konnte nicht hinzugefügt werden",
   "help": "Hilfe",
   "save": "Speichern",
   "hrs": "Std.",
   "error-unknown": "Unbekannter Fehler.",
   "hr": "Std.",
   "remove": "Löschen"
};
sv.i18n.common.en={
   "networkErrorText": "Information could not be updated. Could not contact server.",
   "networkErrorTitle": "Communication with server failed",
   "decimal": ".",
   "cancel": "Cancel",
   "mins": "mins",
   "error-fileExists": "{0} already exists.",
   "ok": "OK",
   "error-fileRenameHeading": "Could not rename file",
   "add": "Add",
   "close": "Close",
   "new": "New",
   "error": "Error",
   "error-fileExistsHeading": "Could not add file",
   "help": "Help",
   "save": "Save",
   "hrs": "hrs",
   "error-unknown": "Unknown error.",
   "remove": "Delete",
   "hr": "hr"
};
sv.i18n.common.es={
   "networkErrorText": "No se ha podido actualizar la información. No se ha podido establecer contacto con el servidor.",
   "networkErrorTitle": "Error en la comunicación con el servidor.",
   "decimal": ",",
   "cancel": "Cancelar",
   "mins": "min",
   "error-fileExists": "{0} ya existe.",
   "ok": "Aceptar",
   "error-fileRenameHeading": "No se ha podido cambiar el nombre del fichero",
   "add": "Añadir",
   "close": "Cerrar",
   "new": "Nuevo",
   "error": "Error",
   "error-fileExistsHeading": "No se ha podido añadir el fichero",
   "help": "Ayuda",
   "save": "Guardar",
   "hrs": "hora",
   "error-unknown": "Error desconocido.",
   "hr": "hora",
   "remove": "Eliminar"
};
sv.i18n.common.fi={
   "networkErrorText": "Aineistoa ei voitu päivittää. Palvelimeen ei saada yhteyttä.",
   "networkErrorTitle": "Ongelma palvelinyhteydessä.",
   "decimal": ",",
   "cancel": "Peruuta",
   "mins": "min",
   "error-fileExists": "{0} tiedosto on jo olemassa.",
   "ok": "OK",
   "error-fileRenameHeading": "Tiedoston nimen vaihto ei onnistunut",
   "add": "Luo tili",
   "close": "Sulje",
   "new": "Uusi",
   "error": "Virhe",
   "error-fileExistsHeading": "Tiedoston lisääminen ei onnistunut",
   "help": "Ohje",
   "save": "Tallenna",
   "hrs": "tuntia",
   "error-unknown": "Tuntematon virhe.",
   "remove": "Poista",
   "hr": "tunti"
};
sv.i18n.common.fr={
   "networkErrorText": "Les informations n'ont pas pu être mises à jour. Impossible de contacter le serveur.",
   "networkErrorTitle": "Erreurs de communication avec le serveur",
   "decimal": ",",
   "cancel": "Annuler",
   "mins": "min",
   "error-fileExists": "{0} existe déjà.",
   "ok": "OK",
   "error-fileRenameHeading": "Le fichier n'a pas pu être renommé",
   "add": "Ajouter",
   "close": "Fermer",
   "new": "Nouveau",
   "error": "Erreur",
   "error-fileExistsHeading": "Impossible d'ajouter le fichier",
   "help": "Aide",
   "save": "Sauvegarder",
   "hrs": "heure",
   "error-unknown": "Erreur inconnue.",
   "hr": "heure",
   "remove": "Supprimer"
};
sv.i18n.common.no={
   "networkErrorText": "Informasjonen kunne ikke oppdateres. Kunne ikke kontakte serveren.",
   "networkErrorTitle": "Feil ved serverkommunikasjon",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finnes allerede.",
   "ok": "OK",
   "error-fileRenameHeading": "Det var ikke mulig å bytte navn på filen",
   "add": "Legg til",
   "close": "Lukk",
   "new": "Ny",
   "error": "Feil",
   "error-fileExistsHeading": "Det var ikke mulig å legge til filen",
   "help": "Hjelp",
   "save": "Lagre",
   "hrs": "time",
   "error-unknown": "Ukjent feil.",
   "remove": "Fjern",
   "hr": "time"
};
sv.i18n.common.pt={"cancel": "Cancelar"};
sv.i18n.common.sv={
   "networkErrorText": "Informationen kunde inte uppdateras. Kunde inte kontakta servern.",
   "networkErrorTitle": "Fel vid serverkommunikation",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finns redan.",
   "ok": "OK",
   "error-fileRenameHeading": "Det gick inte att byta namn på filen",
   "add": "Lägg till",
   "close": "Stäng",
   "new": "Ny",
   "error": "Fel",
   "error-fileExistsHeading": "Det gick inte att lägga till filen",
   "help": "Hjälp",
   "save": "Spara",
   "hrs": "tim",
   "error-unknown": "Okänt fel.",
   "remove": "Ta bort",
   "hr": "tim"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.dialogutil=sv.i18n.util.dialogutil || {};
sv.i18n.util.dialogutil.de={
   "ok": "OK",
   "cancel": "Abbrechen"
};
sv.i18n.util.dialogutil.en={
   "ok": "OK",
   "cancel": "Cancel"
};
sv.i18n.util.dialogutil.es={
   "ok": "Aceptar",
   "cancel": "Cancelar"
};
sv.i18n.util.dialogutil.fi={
   "ok": "OK",
   "cancel": "Peruuta"
};
sv.i18n.util.dialogutil.fr={
   "ok": "OK",
   "cancel": "Annuler"
};
sv.i18n.util.dialogutil.no={
   "ok": "OK",
   "cancel": "Avbryt"
};
sv.i18n.util.dialogutil.sv={
   "ok": "OK",
   "cancel": "Avbryt"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.errorutil=sv.i18n.util.errorutil || {};
sv.i18n.util.errorutil.de={
   "errorMessage": "Es ist ein Problem aufgetreten, das dazu geführt hat, dass die Aufgabe nicht wie vorgesehen ausgeführt werden konnte ({0})",
   "connectionErrorMessageTitle": "Verbindungsproblem",
   "errorMessageTitle": "Es ist ein Fehler aufgetreten",
   "connectionErrorMessage": "Es konnte keine Verbindung zum Server hergestellt werden. Bitte versuchen Sie es später erneut"
};
sv.i18n.util.errorutil.en={
   "errorMessage": "A problem occurred that prevented the task from being performed ({0})",
   "connectionErrorMessageTitle": "Connection error",
   "errorMessageTitle": "An error occurred",
   "connectionErrorMessage": "Could not connect to the server, please try again later"
};
sv.i18n.util.errorutil.es={
   "errorMessage": "Se produjo un problema que impidió que la tarea se realizara según lo previsto ({0}).",
   "connectionErrorMessageTitle": "Problema de conexión",
   "errorMessageTitle": "Ha ocurrido un error",
   "connectionErrorMessage": "No se ha podido conectar con el servidor. Inténtalo de nuevo más tarde."
};
sv.i18n.util.errorutil.fi={
   "errorMessage": "Toimintoa ei voitu virheen takia suorittaa pyydetyllä tavalla ({0})",
   "connectionErrorMessageTitle": "Yhteysongelma",
   "errorMessageTitle": "Tapahtui virhe",
   "connectionErrorMessage": "Palvelimeen ei saatu yhteyttä, kokeile myöhemmin uudelleen"
};
sv.i18n.util.errorutil.fr={
   "errorMessage": "Un problème s'est produit qui a empêché l'exécution de la tâche comme prévu ({0}).",
   "connectionErrorMessageTitle": "Problèmes de connexion",
   "errorMessageTitle": "Une erreur s'est produite",
   "connectionErrorMessage": "Impossible de se connecter au serveur, veuillez réessayer plus tard"
};
sv.i18n.util.errorutil.no={
   "errorMessage": "Det oppsto et problem som gjorde at opplysningen ikke kunne utføres slik det var tenkt ({0})",
   "connectionErrorMessageTitle": "Tilkoblingsproblem",
   "errorMessageTitle": "Det oppsto en feil",
   "connectionErrorMessage": "Kunne ikke koble til serveren, prøv igjen senere"
};
sv.i18n.util.errorutil.sv={
   "errorMessage": "Ett problem uppstod som gjorde att uppgiften inte kunde utföras som det var tänkt ({0})",
   "connectionErrorMessageTitle": "Anslutningsproblem",
   "errorMessageTitle": "Ett fel inträffade",
   "connectionErrorMessage": "Kunde inte ansluta till servern, var god försök igen senare"
};
