/**
 * Copyright (C) Sitevision 2002-2023, all rights reserved
 */
import { hasPortlet, hasSubPortlet } from '../util/findPortlets';

if (hasPortlet('sv-blog-portlet')) {
  import(/*webpackChunkName: "blog-portlet" */ './plugins-slim');

  if (hasSubPortlet('sv-blog-portlet', '.sv-like')) {
    import(/* webpackChunkName: "likableUtil" */ '../Archive/plugins-slim');
  }
}
